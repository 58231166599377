const areas = [
    {
        id: '01',
        startPrice: 350,
        extra: 40
    },
    {
        id: '02',
        startPrice: 400,
        extra: 40
    },
    {
        id: '03',
        startPrice: 400,
        extra: 50
    },
    {
        id: '04',
        startPrice: 400,
        extra: 50
    },
    {
        id: '05',
        startPrice: 400,
        extra: 50
    },
    {
        id: '06',
        startPrice: 430,
        extra: 30
    },
    {
        id: '07',
        startPrice: 430,
        extra: 40
    },
    {
        id: '08',
        startPrice: 430,
        extra: 50
    },
    {
        id: '09',
        startPrice: 520,
        extra: 50
    },
    {
        id: '10',
        startPrice: 500,
        extra: 60
    },
    {
        id: '11',
        startPrice: 570,
        extra: 70
    },
    {
        id: '12',
        startPrice: 500,
        extra: 50
    },
    {
        id: '13',
        startPrice: 490,
        extra: 80
    },
    {
        id: '14',
        startPrice: 500,
        extra: 100
    },
    {
        id: '15',
        startPrice: 530,
        extra: 120
    },
    {
        id: '16',
        startPrice: 540,
        extra: 100
    },
    {
        id: '17',
        startPrice: 600,
        extra: 100
    },
    {
        id: '18',
        startPrice: 750,
        extra: 120
    },
    {
        id: '19',
        startPrice: 1500,
        extra: 250
    }
];

export default areas;
